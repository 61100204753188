<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>提现管理</template>
      <template v-slot:secondMenu>提现详情</template>
    </breadcrumb-nav>
    <el-card shadow="never" class="standard-margin">
      <span class="font-title-medium">账户信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">账号金额</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{userExt.rebate | floatFormat }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">可用金额</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{ userExt.rebate - userExt.auditVal | floatFormat }}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">冻结时间</el-col>
          <el-col class="form-border font-small" :span="18">{{withdrawItem.createTime | dateFormat}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">总冻结金额</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{ userExt.auditVal| floatFormat}}</el-col>
        </el-row>
      </div>
      <span class="font-title-medium" >提现信息</span>
      <div class="form-container-border">
        <el-row>
          <el-col :span="6" class="form-border form-left-bg font-small">单号</el-col>
          <el-col class="form-border font-small" :span="18">{{withdrawItem.applyId}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">状态</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{withdrawItem.status | formatStatus}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">账号</el-col>
          <el-col class="form-border font-small" :span="18">{{withdrawItem.account}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">姓名</el-col>
          <el-col class="form-border font-small" :span="18">{{withdrawItem.name}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">申请时间</el-col>
          <el-col class="form-border font-small" :span="18">{{withdrawItem.createTime | dateFormat}}</el-col>
        </el-row>
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6">提现金额</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{withdrawItem.amount | floatFormat}}</el-col>
        </el-row>
        <el-row v-show="withdrawItem.status!==1">
          <el-col class="form-border form-left-bg font-small" :span="6">审核备注</el-col>
          <el-col class="form-border font-small form-color" :span="18">{{withdrawItem.auditNote}}</el-col>
        </el-row>
      </div>
      <div class="form-container-border" v-show="withdrawItem.status===1">
        <el-row>
          <el-col class="form-border form-left-bg font-small" :span="6" style="height:52px;line-height:32px">审核备注</el-col>
          <el-col class="form-border font-small" :span="18">
            <el-input  size="small" v-model="updateParam.auditNote" style="width:200px;margin-left: 10px"></el-input>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="withdrawItem.status===1">
        <el-button type="primary" size="small" @click="handleUpdateStatus(2)">同意提现</el-button>
        <el-button type="danger" size="small" @click="handleUpdateStatus(3)">拒绝提现</el-button>
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
      <div style="margin-top:15px;text-align: center" v-show="withdrawItem.status!==1">
        <el-button type="success" size="small" @click="goBack">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import { getWithDrawApplyDetailRequest, updateWithDrawRequest } from '../../network/user'
export default {
  name: 'Detail',
  components: {
    BreadcrumbNav
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 1:
          return '审核中'
        case 2:
          return '同意'
        case 3:
          return '拒绝'
        case 4:
          return '成功'
        case 5:
          return '失败'
        default:
          return '未知'
      }
    }
  },
  data () {
    return {
      applyId: 0,
      withdrawItem: {},
      userExt: {},
      updateParam: {
        applyId: 0,
        status: 0,
        auditUser: '',
        auditNote: ''
      }
    }
  },
  created () {
    console.log('-------hello this.applyId:')
    this.applyId = this.$route.query.applyId
    console.log('-------this.applyId:', this.applyId)
    this.getWithdrawItem()
  },
  methods: {
    getWithdrawItem () {
      getWithDrawApplyDetailRequest({ applyId: this.applyId }).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取提现信息失败', 'error')
        }
        this.withdrawItem = result.data.withdraw
        this.userExt = result.data.userExt
        console.log('rebate item:', this.userExt)
      })
    },
    handleUpdateStatus (status) {
      this.updateParam.status = status
      this.updateParam.applyId = parseInt(this.applyId)
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (status === 2 || status === 3) {
        this.updateParam.auditUser = userInfo.name
        if (this.updateParam.auditNote === '') {
          return this.alertMessage('请输入审核备注', 'error')
        }
      }
      updateWithDrawRequest(this.updateParam).then(res => {
        if (res.status !== 200) {
          if (this.updateParam.status === 2) {
            return this.alertMessage('提现失败', 'error')
          } else {
            return this.alertMessage('更新提现信息失败', 'error')
          }
        }
        this.goBack()
      })
    },
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>
.detail-container {
}

.standard-margin {
  margin-top: 15px;
}
.form-border {
  border-right: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  padding: 10px;
}

.form-container-border {
  border-left: 1px solid #DCDFE6;
  border-top: 1px solid #DCDFE6;
  margin-top: 15px;
  margin-bottom: 15px;
}

.form-left-bg {
  background: #F2F6FC;
}
.form-color {
  color: red;
}
</style>
